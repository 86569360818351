<template>
  <div>
    <div class="title">企业认证</div>
    <div class="form">
      <el-form ref="form" :model="enterpriseInfo" :rules="rules" label-width="180px"
               style="width: 600px; margin-left: 160px;" size="small">
        <el-form-item label="企业名称" prop="fullNameOfEnterprise">
          <el-input v-model="enterpriseInfo.fullNameOfEnterprise" style="width: 260px;" placeholder="企业全称必须与营业执照一致"
                    @input="checkName" :disabled="authAuditResult === '0'"></el-input>
        </el-form-item>
        <el-form-item label="统一社会信用代码" prop="unifiedSocialCreditCode">
          <el-input v-model="enterpriseInfo.unifiedSocialCreditCode" style="width: 260px;"
                    :disabled="authAuditResult === '0'"></el-input>
        </el-form-item>
        <el-form-item label="联系人" prop="contactPerson">
          <el-input v-model="enterpriseInfo.contactPerson" style="width: 260px;"></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="contactNumber">
          <el-input v-model="enterpriseInfo.contactNumber" style="width: 260px;"></el-input>
        </el-form-item>
        <el-form-item label="传真" prop="fax">
          <el-input v-model="enterpriseInfo.fax" style="width: 260px;"></el-input>
        </el-form-item>
        <el-form-item label="省份" prop="provinceAdcode">
          <el-select
            v-model="enterpriseInfo.provinceAdcode"
            placeholder=" "
            @change="selectProvince"
            style="width: 260px;"
            :disabled="authAuditResult === '0'"
          >
            <el-option
              v-for="(item, index) of provinceArr"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="城市" prop="cityAdcode">
          <el-select
            v-model="enterpriseInfo.cityAdcode"
            placeholder=" "
            :disabled="authAuditResult === '0'"
            style="width: 260px;"
          >
            <el-option
              v-for="(item, index) of cityArr"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="办公地址" prop="officeAddress">
          <el-input v-model="enterpriseInfo.officeAddress" style="width: 260px;"
                    :disabled="authAuditResult === '0'"></el-input>
        </el-form-item>
        <!-- <el-form-item label="是否新版置业执照" prop="isNewBusinessLicense" v-if="authAuditResult !== '0'">
          <el-radio-group v-model="enterpriseInfo.isNewBusinessLicense">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="是否三码和一" prop="isThreeYardsInOne" v-if="authAuditResult !== '0'">
          <el-radio-group v-model="enterpriseInfo.isThreeYardsInOne">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item> -->
        <el-form-item label="营业执照" prop="businessLicense">
          <div style="margin-top: 5px;">
            <div style="height: 25px;font-size: 12px;font-weight: bold;line-height: 25px;color: #7a7a7a;">
              新版营业执照
            </div>
            <el-button v-if="isPDF(businessSrc)" type="primary" @click="downPDF(businessSrc)"> 下载pdf </el-button>
            <img v-else :src="businessSrc" style="width:120px;"/>

            <el-upload :on-exceed="handleExceed" :limit="1" :on-remove="handleRemove" class="upload-demo"
                       :data="{ tag: 'businessLicense' }" action accept=".jpg,.jpeg,.png,.pdf,.JPG,.JPEG,.PBG,.PNG"
                       :http-request="uploadFile" :file-list="fileList" :before-upload="beforeUploadImg">
              <el-button size="mini" type="primary" :disabled="authAuditResult === '0'">点击上传</el-button>
              <span style="color:red;" v-show="businessUpload">
                上传中...</span>
            </el-upload>
            <div class="label">新版营业执照</div>
            <div style="line-height:20px;font-size:14px;color:#ff6600;">
              提示：请上传扫描件或复印件，须以图片或pdf形式上传。每个文件大小不超过5MB.
              如果您公司是新版的营业执照，则只须上传新版营业执照即可
            </div>
          </div>
        </el-form-item>
        <el-form-item label="服务协议" prop="serviceAgreement" v-if="authAuditResult !== '0'">
          <div style="margin-top: 5px;">
            <div style="height: 25px;font-size: 12px;font-weight: bold;line-height: 25px;color: #7a7a7a;">
              服务协议
            </div>
            <el-button v-if="isPDF(serviceSrc)" type="primary" @click="downPDF(businessSrc)"> 下载pdf </el-button>
            <img :src="serviceSrc" style="width:120px;"/>

            <el-upload :on-exceed="handleExceeds" :limit="1" :on-remove="handleRemoves" class="upload-demo"
                       :data="{ tag: 'serviceAgreement' }" action accept=".jpg,.jpeg,.png,.pdf,.JPG,.JPEG,.PBG,.PNG"
                       :http-request="uploadFile" :file-list="fileList" :before-upload="beforeUploadImg">
              <el-button size="mini" type="primary">点击上传</el-button>
              <span style="color:red;" v-show="serviceUpload">
                上传中...</span>
            </el-upload>
            <div class="label">服务协议</div>
            <div style="line-height:20px;font-size:14px;color:#ff6600;">
              提示：请上传加盖公章的扫描件或复印件，须以图片或pdf形式上传。大小不超过5MB.
            </div>
          </div>
        </el-form-item>
        <el-form-item>
          <div class="submit-btn" :class="{
              'disabled-btn':
                authAuditResult !== '0' &&
                (!businessLicense || !serviceAgreement)
            }" @click="onSubmit" :disabled="
              authAuditResult !== '0' &&
                (businessLicense === '' || serviceAgreement === '')
            ">
            {{ authAuditResult !== "0" ? "申请认证" : "保存" }}
          </div>
        </el-form-item>
      </el-form>
    </div>

    <div class="audit-title">申请审核信息</div>
    <div>
      <el-table :data="tableData" :header-cell-style="{
          'text-align': 'center',
          'font-weight': 'normal',
          'font-size': '12px',
          color: '#333'
        }" :cell-style="{
          'text-align': 'center',
          'font-weight': 'normal',
          'font-size': '12px',
          color: '#333'
        }" border style="width: 100%" size="mini">
        <el-table-column prop="realName" label="真实姓名" width="560">
        </el-table-column>
        <el-table-column prop="applicationTime" label="申请时间" width="180">
        </el-table-column>
        <el-table-column prop="status" label="状态">
          <template slot-scope="scope">{{
            getAuthStatus(scope.row.status)
            }}
          </template>
        </el-table-column>
        <el-table-column prop="auditTime" label="审核时间"></el-table-column>
        <el-table-column prop="auditOpinion" label="审核意见">
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import mapUtil from '../../../utils/MapUtils'

const http = axios.create({
  baseURL: '/',
  headers: {
    'Content-Type': 'application/json'
  }
})
export default {
  data () {
    return {
      rules: {
        fullNameOfEnterprise: [
          {
            type: 'string',
            required: true,
            message: '请填写企业全称',
            trigger: 'blur'
          }
        ],
        unifiedSocialCreditCode: [
          {
            type: 'string',
            required: true,
            message: '请填写统一社会信用代码',
            trigger: 'blur'
          }
        ],
        contactPerson: [
          {
            type: 'string',
            required: true,
            message: '请填写联系人',
            trigger: 'blur'
          }
        ],
        contactNumber: [
          {
            type: 'string',
            required: true,
            message: '请填写联系电话',
            trigger: 'blur'
          }
        ],
        fax: [
          {
            type: 'string',
            required: true,
            message: '请填写传真',
            trigger: 'blur'
          }
        ],
        officeAddress: [
          {
            type: 'string',
            required: true,
            message: '请填写办公地址',
            trigger: 'blur'
          }
        ],
        provinceAdcode: [
          {
            required: true,
            message: '请选择省份',
            trigger: 'change'
          }
        ],
        cityAdcode: [
          {
            required: true,
            message: '请选择城市',
            trigger: 'change'
          }
        ],
        // businessLicense: [
        //   {
        //     type: 'string',
        //     required: true,
        //     message: '请上传企业三证',
        //     trigger: 'blur'
        //   }
        // ],
        isNewBusinessLicense: [{ required: true, trigger: 'change' }],
        isThreeYardsInOne: [{ required: true, trigger: 'change' }]
        // serviceAgreement: [{ type: 'string', required: true }]
      },
      fileList: [],
      tableData: [],
      enterpriseInfo: {
        fullNameOfEnterprise: '',
        unifiedSocialCreditCode: '',
        contactPerson: '',
        contactNumber: '',
        fax: '',
        officeAddress: '',
        isNewBusinessLicense: 1,
        isThreeYardsInOne: 1
      },

      serviceAgreement: '',
      businessLicense: '',

      businessSrc: '',
      serviceSrc: '',
      authStatus: [],

      serviceUpload: false,
      businessUpload: false,
      // 省市
      provinceArr: [],
      cityArr: []
    }
  },
  created () {
    // 初始化省 数据
    this.provinceArr = mapUtil.province
    this.getEnterpriseInfo()
    this.getTypes()
  },
  computed: {
    ...mapState({
      authAuditResult: state => state.Ylt.authAuditResult
    }),
    userInfo () {
      return this.$store.state.UserInfo
    }
  },
  methods: {
    isPDF (src) {
      if (/\.(pdf|PDF)$/.test(src)) {
        return true
      }
    },
    downPDF (src) {
      var file = new Blob([src], {
        type: 'application/pdf'
      })
      var link = document.createElement('a')
      link.download = self.pdfName
      link.href = URL.createObjectURL(file)
      link.click()
      URL.revokeObjectURL(file)
    },
    handleExceed () {
      this.$message.warning('当前限制上传一个文件！')
    },
    handleExceeds () {
      this.$message.warning('当前限制上传一个文件！')
    },
    handleRemove () {
      this.businessSrc = ''
    },
    handleRemoves () {
      this.serviceSrc = ''
    },
    getTypes () {
      let params = {}
      this.$post('355cc1ed-6673-a8fe-737a-7b915fca6ff8', params)
        .then(res => {
          const { data } = res
          this.authStatus = data.authStatus
        })
        .catch(err => {
          console.log(err)
        })
    },
    getAuthStatus (key) {
      let authStatus = this.authStatus
      let m = new Map()
      authStatus.forEach(item => {
        m.set(item.code, item.value)
      })
      return m.get(key)
    },
    onSubmit () {
      if (
        this.authAuditResult !== '0' &&
                    (!this.businessLicense || !this.serviceAgreement)
      ) {
        return
      }
      if (
        !this.enterpriseInfo.fullNameOfEnterprise ||
                    !this.enterpriseInfo.contactPerson ||
                    !this.enterpriseInfo.contactNumber ||
                    !this.enterpriseInfo.fax ||
                    !this.enterpriseInfo.officeAddress ||
                    !this.enterpriseInfo.provinceAdcode ||
                    !this.enterpriseInfo.cityAdcode ||
                    !this.enterpriseInfo.isNewBusinessLicense ||
                    !this.enterpriseInfo.isThreeYardsInOne ||
                    !this.enterpriseInfo.unifiedSocialCreditCode
      ) {
        this.$alert('请填写相关信息')
        return
      }
      let params = {
        process_id: '00000201',
        execution_type: 'set', // get 代表读请求 获取数据,  set 代表写请求 编辑等操作
        nextProcessNode: '001', // 上面接口的  button.NodeId_1字段的值
        nodeInfo: {
          enterpriseFullName: this.enterpriseInfo.fullNameOfEnterprise,
          contactPerson: this.enterpriseInfo.contactPerson, // 联系人
          contactNumber: this.enterpriseInfo.contactNumber, // 联系电话
          fax: this.enterpriseInfo.fax, // 传真
          provinceAdcode: this.enterpriseInfo.provinceAdcode,
          cityAdcode: this.enterpriseInfo.cityAdcode,
          officeAddress: this.enterpriseInfo.officeAddress,
          isNewBusinessLicense: this.enterpriseInfo.isNewBusinessLicense,
          isThreeYardsInOne: this.enterpriseInfo.isThreeYardsInOne,
          socialUnifiedCreditCode: this.enterpriseInfo.unifiedSocialCreditCode,
          businessLicense: this.businessLicense, // 营业执照
          serviceAgreement: this.serviceAgreement
        }
      }
      this.$post('a6c611cc-ded1-4a49-8823-0c66f6ebf7d9', params)
        .then(res => {
          if (res.succeed === 0) {
            this.$message({
              message: res.errorMsg,
              type: 'success'
            })
            this.$router.push('/userInfo/userCenter')
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    getEnterpriseInfo () {
      console.log(this.$store.state.UserInfo)
      let params = {
        process_id: '00000201', // 流程ID
        // 传参  查询则是查询条件  可传可不传等
        nodeInfo: {}
      }
      this.$post('a6c611cc-ded1-4a49-8823-0c66f6ebf7d9', params)
        .then(res => {
          const data = res.data
          this.enterpriseInfo.fullNameOfEnterprise = data.fullNameOfEnterprise
          this.enterpriseInfo.unifiedSocialCreditCode =
                            data.unifiedSocialCreditCode
          this.enterpriseInfo.contactPerson = data.contactPerson
          this.enterpriseInfo.contactNumber = data.contactNumber
          this.enterpriseInfo.fax = data.fax
          this.enterpriseInfo.officeAddress = data.officeAddress
          this.$set(this.enterpriseInfo, 'provinceAdcode', data.provinceAdcode)
          this.cityArr = mapUtil.getCityArr(data.provinceAdcode)
          this.$set(this.enterpriseInfo, 'cityAdcode', data.cityAdcode)
          this.businessLicense = data.businessLicense
          this.serviceAgreement = data.serviceAgreement

          this.getImg(this.businessLicense)
          this.getPic(this.serviceAgreement)

          this.tableData = data.applicationReviewInformations
        })
        .catch(err => {
          console.log(err)
        })
    },
    getImg (rowData) {
      http
        .get('/file/getFilePath?fileId=' + rowData)
        .then(res => {
          const { data } = res.data
          this.businessSrc = `/downfile${data['common_file@path']}`
        })
        .catch(err => {
          console.log(err)
        })
    },
    getPic (rowData) {
      http
        .get('/file/getFilePath?fileId=' + rowData)
        .then(res => {
          const { data } = res.data
          this.serviceSrc = `/downfile${data['common_file@path']}`
        })
        .catch(err => {
          console.log(err)
        })
    },
    uploadFile (param) {
      if (param.data.tag === 'businessLicense') {
        this.businessUpload = true
      } else if (param.data.tag === 'serviceAgreement') {
        this.serviceUpload = true
      }
      let p = new Promise((resolve, reject) => {
        // 上传 下载key获取
        let params = {
          process_id: '00000201',
          nextProcessNode: '001',
          addr: 'getDownloadKey', // 获取列表 接口返回的url.downloadKeyUrl.addr 的值
          nodeInfo: {}
        }
        this.$post('a6c611cc-ded1-4a49-8823-0c66f6ebf7d9', params)
          .then(res => {
            resolve(res.data)
          })
          .catch(err => {
            console.log(err)
          })
      })

      p.then(res => {
        const fd = new FormData()
        fd.append('file', param.file)
        fd.append('key', res)
        fd.append('systemId', this.userInfo.id)
        fd.append(
          'orgId',
          this.userInfo.organizationId.length === 0
            ? ''
            : this.userInfo.organizationId[0]
        )

        http.post('/file/upload', fd).then(res => {
          if (param.data.tag === 'businessLicense') {
            this.businessUpload = false
            this.businessLicense = res.data.data
            this.getImg(res.data.data)
          } else if (param.data.tag === 'serviceAgreement') {
            this.serviceAgreement = res.data.data
            this.serviceUpload = false
            this.getPic(res.data.data)
          }
        })
      })
    },
    beforeUploadImg (file) {
      let fileSize = file.size / 1024 / 1024 < 5
      if (!fileSize) {
        this.$message.warning('大小不能超过5MB')
        return false
      }
    },
    checkName () {
      // eslint-disable-next-line no-useless-escape
      this.enterpriseInfo.fullNameOfEnterprise = this.enterpriseInfo.fullNameOfEnterprise.replace(/[\/\\]/g, '')
    },
    // 三级联动
    selectProvince (id) {
      this.cityArr = []
      this.$set(this.enterpriseInfo, 'cityAdcode', '')
      this.cityArr = mapUtil.getCityArr(id)
    }
  }
}
</script>

<style lang="scss" scoped>
  .title {
    border-bottom: 3px solid #1d74e0;
    height: 35px;
    line-height: 27px;
    font-size: 16px;
    font-weight: bold;
    color: #666666;
    padding-left: 30px;
    padding-right: 25px;
    margin-top: 15px;
  }

  .form {
    border: 1px solid #e5e5e5;
    margin: 10px 0;
    padding: 10px;
    background: #fff;

    .submit-btn {
      background-color: #2d5171;
      color: #fff;
      font-size: 12px;
      line-height: 20px;
      width: 126px;
      padding: 5px 20px;
      text-align: center;
      box-sizing: border-box;
      margin: 0 auto;
      cursor: pointer;

      &.disabled-btn {
        cursor: not-allowed;
      }
    }

    .label {
      margin: 5px 0 5px 0;
      width: 118px;
      height: 25px;
      line-height: 25px;
      color: #666;
      border: 1px solid #d4d4d4;
      font-family: "微软雅黑";
      padding: 0;
      text-align: center;
      cursor: pointer;
    }
  }

  .audit-title {
    text-align: left;
    font-size: 14px;
    font-weight: bold;
    line-height: 40px;
    height: 40px;
    color: #3366cc;
  }

</style>
